import React from "react"
import Img from "gatsby-image"
import Layout from "../../components/layout"
import { Container, Row, Col } from 'react-bootstrap';
import { graphql } from "gatsby"
import SEO from "../../components/seo"


export default function Supplier({ data }) {
  const supplier = data.wpSupplier.supplier
  const title = data.wpSupplier.title

  const products = supplier.products && supplier.products.map(({product, index}) => (
    <li key={index}>{product}</li>
  ));

  const certifications = supplier.certifications && supplier.certifications.map(({certification, index}) => (
    <li key={index}>{certification}</li>
  ));

  return (
    <Layout>
      <SEO
        title={data.wpSupplier.seo.title}
        description={data.wpSupplier.seo.metaDesc}
      />
      <Container fluid="xl">
        <div className="supplier-post">
          <Row>
          <Col md={8}>
            {title && <h1>{title}</h1>}
            {supplier.description && <div className="description" dangerouslySetInnerHTML={{__html: supplier.description}} />}
            {supplier.services &&
              <>
                <h3>Products and Services</h3>
                <div dangerouslySetInnerHTML={{__html: supplier.services}} />
              </>
            }
            {certifications && 
              <>
                <h3>Certifications</h3>
                <ul>{certifications}</ul>
              </>
            }
            
            <div className="images">
            {supplier.images && supplier.images.map((images, index) => (
							<div className="group" key={index}>
                <Img key={images.id} fluid={images.remoteFile.childImageSharp.fluid}
                sizes={{ ...images.remoteFile.childImageSharp.fluid, aspectRatio: 5 / 5 }} 
                />
							</div>
						))}
            </div>
            
            </Col>
            <Col md={{ span: 3, offset: 1 }} className="details">
              {supplier.keyContact && <><h6>Key Contact</h6><p>{supplier.keyContact}</p></>}
              {supplier.address && <><h6>Address</h6><p>{supplier.address}</p></>}
              {supplier.phoneNumber && <><h6>Phone Number</h6><p>{supplier.phoneNumber}</p></>}
              {supplier.email && <><h6>Email</h6><p>{supplier.email}</p></>}
              {supplier.website && <div className="website"><a href={supplier.website} target="_blank" rel="noreferrer" className="button primary">Visit Website</a></div>}

              {products && 
                <div>
                  <h5>Products</h5>
                  <ul>{products}</ul>
                </div>
              }

            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  )
}


export const query = graphql`
  query($uri: String!) {
    wpSupplier(uri: { eq: $uri }) {
      seo {
        title
        metaDesc
      }
      title
      supplier {
        description
        services
        keyContact
        address
        phoneNumber
        email
        website
        products {
          product
        }
        certifications {
          certification
        }
        images {
          id
          remoteFile {
            childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
